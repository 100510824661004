import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Pageheader from "../components/pageheader";
import "../stylesheets/components/web.scss";



const Web = ({ data }) => {
  const web = data.allContentfulWeb.edges;
  const { heroImage, title, body } = data.contentfulPageHeader;

  const headerTitle = data.contentfulPageHeader.title;
  const headerDesc = data.contentfulPageHeader.body.body;
 const headerImage = data.contentfulPageHeader.heroImage.fixed.src;

  return (

    <Layout>
      <SEO title={headerTitle} pageDescription={headerDesc} heroImage={headerImage} keywords={[`gatsby`, `application`, `react`]} />
      <Pageheader title={title} image={heroImage} body={body}/>
      <div className="container">
        <div className="row">
        {web.map(({ node: post }, index) => (
             <div key={post.id} className="col-12 col-md-6 webitems">
               <Link to={`/web/${post.slug}`}>
                  <img src={post.heroImage.fixed.src} className="img-fluid zoomImg" alt={post.title}/>
                 
                  </Link>
                  <Link to={`/web/${post.slug}`}><h2>{post.title}</h2></Link>
               </div>
              
        ))}
        </div>
      </div>
      
          
           


    </Layout>
  );
};
export default Web;
export const query = graphql`
  query WebPageQuery {
    allContentfulWeb(limit: 1000) {
      edges {
        node {
          id
          title
          slug
          heroImage {
            fixed(height: 800, width: 1200) {
               src
            }
            fluid(maxWidth: 1200, quality: 100) {
              src
            }
            file {
              url
            }
            title
          }
          imageGallery {
            fixed(width: 800, height: 800) {
              src
            }
            fluid(maxWidth: 1200, quality: 100) {
              src
            }
            file {
              url
            }
            title
          }
          technology {
            technologyName
            
            id
          }
          link
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    contentfulPageHeader(title: {eq: "web"}) {
      heroImage {
        fixed(width: 2000, height: 500) {
          src
        }
        title
      }
      title
      body {
        body
      }
    }
  }
`;
